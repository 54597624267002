import {
    useContext, useState,
} from 'react';

import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { updateGallery } from 'src/store/galleryData/galleryStore';
import AddGalleryUrlRow from 'src/components/ManageGallery/GalleryDetails/GalleryUrls/AddGalleryUrlRow';
import AddToTable from 'src/components/common/AddToTable';
import { BUILD_TLP } from 'src/constants';

const AddGalleryUrls = (): JSX.Element => {
    const { galleryData: { gallery }, dispatch } = useContext(ManageGalleryContext);
    const [newUrls, setNewUrls] = useState([] as Services.Galleries.GalleryUrl[]);

    const newGalleryUrl = (): void => {
        const newUrl = {
            culture: 'en-ie',
            galleryId: gallery.id,
            isDefault: false,
            name: '',
            buildTlp: BUILD_TLP.On,
        };

        setNewUrls([
            ...newUrls,
            newUrl,
        ]);
    };

    const saveGalleryName = (GalleryUrl: Services.Galleries.GalleryUrl): void => {
        const newGalleryUrls = gallery.galleryNames.slice();

        newGalleryUrls.push(GalleryUrl);
        dispatch(updateGallery({
            ...gallery,
            galleryNames: newGalleryUrls,
        }));
    };

    return (
        <AddToTable
            addedRows={newUrls}
            setAddedRows={setNewUrls}
            createNewRow={newGalleryUrl}
            saveRow={saveGalleryName}
            addButtonText="Add Gallery URL"
        >
            {({ index, row, ...rest }): JSX.Element => (
                <AddGalleryUrlRow index={index} key={index} url={row} {...rest} />
            )}
        </AddToTable>
    );
};

export default AddGalleryUrls;
